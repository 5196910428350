export default {
    data(){
        return {
            dd_roles: [
                { label: 'Admin', value: 1 },
                { label: 'Editor', value: 2 },
                { label: 'SuperAdmin', value: 3 },
                { label: '4Mind Doc', value: 4 },
            ],
        };
    },
    methods: {
        RoleLabel(role){
            let name = '-';
            this.dd_roles.forEach(s => {
                if (s.value === role) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}
